@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+000-5FF;
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'),
    url('../fonts/roboto-v20-latin-regular.woff') format('woff'),
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+000-5FF;
  src:
    local('Roboto Italic'),
    local('Roboto-Italic'),
    url('../fonts/roboto-v20-latin-italic.woff2') format('woff2'),
    url('../fonts/roboto-v20-latin-italic.woff') format('woff'),
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  unicode-range: U+000-5FF;
  src:
    local('Roboto Medium'),
    local('Roboto-Medium'),
    url('../fonts/roboto-v20-latin-500.woff2') format('woff2'),
    url('../fonts/roboto-v20-latin-500.woff') format('woff'),
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  unicode-range: U+000-5FF;
  src:
    local('Roboto Medium Italic'),
    local('Roboto-MediumItalic'),
    url('../fonts/roboto-v20-latin-500italic.woff2') format('woff2'),
    url('../fonts/roboto-v20-latin-500italic.woff') format('woff'),
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+000-5FF;
  src:
    local('Roboto Bold'),
    local('Roboto-Bold'),
    url('../fonts/roboto-v20-latin-700.woff2') format('woff2'),
    url('../fonts/roboto-v20-latin-700.woff') format('woff'),
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+000-5FF;
  src:
    local('Roboto Bold Italic'),
    local('Roboto-BoldItalic'),
    url('../fonts/roboto-v20-latin-700italic.woff2') format('woff2'),
    url('../fonts/roboto-v20-latin-700italic.woff') format('woff'),
}

@font-face {
  font-family: 'FontAwesome';
  src: url('../plugins/font-awesome/fonts/fontawesome-webfont.eot?v=4.7.0');
  src: url('../plugins/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('../plugins/font-awesome/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('../plugins/font-awesome/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('../plugins/font-awesome/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('../plugins/font-awesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
